<template>
  <div class="app-input-wrapper">
    <template v-if="view">
      <slot name="view" :type="type" :label="label" :value="value">
        <h3 class="app-input--view-label">{{ label }}</h3>
        <div class="mt-1 app-input--view-value" v-bind="viewBinds">
          <slot name="view-value" :type="type" :label="label" :value="value">
            <template v-if="type === 'switch'">
              <v-switch disabled :input-value="value" />
            </template>

            <template v-else-if="value">
              <template v-if="type === 'picture'">
                <img
                  class="app-input--view-picture"
                  :src="src"
                  v-bind="viewBinds"
                />
              </template>

              <template v-else-if="type === 'select-server'">
                {{ selectServerDisplay }}
              </template>
              <template v-else-if="type === 'html-editor'">
                <div v-html="value"></div>
              </template>
              <template v-else-if="type === 'chips-server'">
                <v-chip
                  v-for="item in value"
                  link
                  :key="item.id"
                  :color="(binds.chip && binds.chip.color) || 'primary'"
                  :to="
                    binds.chip && binds.chip.router
                      ? binds.chip.router(item)
                      : null
                  "
                >
                  <v-icon v-if="binds.chip && binds.chip.icon" left>
                    {{ binds.chip.icon }}
                  </v-icon>
                  {{ item[(binds.chip && binds.chip.value) || 'name'] }}
                </v-chip>
              </template>
              <template v-else-if="type === 'chips'">
                <v-chip-group>
                  <v-chip
                    v-for="val in value"
                    :key="val"
                    :value="val"
                    :color="'primary'"
                  >
                    <slot :value="val">{{ val }}</slot>
                    <!--                    {{-->
                    <!--                      findItemFromValue(val)-->
                    <!--                        ? findItemFromValue(val).label-->
                    <!--                        : val-->
                    <!--                    }}-->
                  </v-chip>
                </v-chip-group>
              </template>

              <template v-else>
                {{ value }}
              </template>
            </template>
            <template v-else>N/A</template>
          </slot>
        </div>
      </slot>
    </template>
    <validation-provider
      v-else
      class="app-input"
      ref="validation"
      v-slot="{ errors }"
      :name="name"
      :rules="rules"
    >
      <template v-if="isNormalType">
        <v-text-field
          :type="type"
          :disabled="disabled"
          :error-messages="errors"
          :value="value"
          v-bind="binds"
          v-on="events"
          @input="onInput"
          @blur="handleBlur"
          @keydown="handleKeyDown"
        >
          <template v-slot:label>
            <div>
              {{ label }} <small class="error--text" v-if="isRequired">*</small>
            </div>
          </template>
          <!-- @slot default -->
          <slot />
        </v-text-field>
      </template>

      <template v-if="type === 'textarea'">
        <v-textarea
          clearable
          no-resize
          clear-icon="mdi-close-circle"
          :disabled="disabled"
          :error-messages="errors"
          :value="value"
          v-bind="binds"
          v-on="events"
          @input="onInput"
        >
          <template v-slot:label>
            <div>
              {{ label }} <small class="error--text" v-if="isRequired">*</small>
            </div>
          </template>
          <!-- @slot default -->
          <slot />
        </v-textarea>
      </template>
      <template v-if="type === 'html-editor'">
        <vue-editor
          v-bind="binds"
          :disabled="disabled"
          :error-messages="errors"
          :value="value"
          @input="onInput"
        ></vue-editor>
      </template>

      <template v-else-if="type === 'switch'">
        <v-switch
          :label="label"
          :disabled="disabled"
          :error-messages="errors"
          :input-value="value"
          v-bind="binds"
          v-on="events"
          @change="onInput"
        >
          <!-- @slot default -->
          <slot />
        </v-switch>
      </template>

      <template v-else-if="type === 'slider'">
        <v-slider
          thumb-label="always"
          :label="label"
          :disabled="disabled"
          :error-messages="errors"
          :value="value"
          v-bind="binds"
          v-on="events"
          @input="onInput"
        >
          <!-- @slot default -->
          <slot />
        </v-slider>
      </template>

      <template v-else-if="type === 'checkbox'">
        <v-checkbox
          :label="label"
          :disabled="disabled"
          :error-messages="errors"
          :input-value="value"
          v-bind="binds"
          v-on="events"
          @change="onInput"
        >
          <!-- @slot default -->
          <slot />
        </v-checkbox>
      </template>

      <template v-else-if="type === 'radio'">
        <p class="v-label theme--light mb-0">{{ label }}</p>
        <v-radio-group
          :disabled="disabled"
          :error-messages="errors"
          :value="value"
          v-bind="groupBinds"
          @change="onInput"
        >
          <!-- @slot default -->
          <slot :items="items" />
          <v-radio
            v-for="item in items"
            :key="item.id || item.label"
            :label="item.label"
            :value="item.value || item.label"
            v-bind="binds"
            v-on="events"
          />
        </v-radio-group>
      </template>

      <template v-else-if="type === 'file'">
        <v-file-input
          show-size
          truncate-length="15"
          :label="label"
          :disabled="disabled"
          :error-messages="errors"
          :value="value"
          v-bind="binds"
          v-on="events"
          @change="onInput"
        >
          <!-- @slot default -->
          <slot />
        </v-file-input>
      </template>
      <template v-else-if="type === 'year-selection'">
        <v-autocomplete
          clearable
          :items="yearItems"
          :label="label"
          :item-text="binds.itemText || 'label'"
          :item-value="binds.itemValue || 'value'"
          :disabled="disabled"
          @input="onInput"
          v-bind="binds"
          v-on="events"
          :value="value"
          :error-messages="errors"
        >
          <template v-slot:label>
            <div>
              {{ label }}
              <small class="error--text" v-if="isRequired">*</small>
            </div>
          </template>

          <template v-slot:no-data>
            {{ $t('label.noData') }}
          </template>
        </v-autocomplete>
      </template>
      <template v-else-if="type === 'datepicker'">
        <v-menu min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              prepend-icon="mdi-calendar"
              readonly
              :disabled="disabled"
              :error-messages="errors"
              :value="value"
              v-bind="attrs"
              v-on="on"
            >
              <template v-slot:label>
                <div>
                  {{ label }}
                  <small class="error--text" v-if="isRequired">*</small>
                </div>
              </template>
              <!-- @slot text-field -->
              <slot name="text-field" />
            </v-text-field>
          </template>
          <v-date-picker
            :disabled="disabled"
            :value="value"
            v-bind="binds"
            @input="onInput"
          >
            <!-- @slot default -->
            <slot />
          </v-date-picker>
        </v-menu>
      </template>

      <template v-else-if="type === 'select'">
        <v-autocomplete
          clearable
          :label="label"
          :items="items"
          :item-text="binds.itemText || 'label'"
          :item-value="binds.itemValue || 'value'"
          :disabled="disabled"
          :error-messages="errors"
          :value="value"
          :multiple="multiple"
          :chips="chips"
          v-bind="binds"
          v-on="events"
          @input="onInput"
        >
          <template v-slot:label>
            <div>
              {{ label }}
              <small class="error--text" v-if="isRequired">*</small>
            </div>
          </template>

          <template v-slot:no-data>
            {{ $t('label.noData') }}
          </template>
        </v-autocomplete>
      </template>
      <template v-else-if="type === 'select-server'">
        <v-autocomplete
          clearable
          return-object
          :label="label"
          :item-text="binds.itemText || 'name'"
          :items="serverItems"
          :loading="loading"
          :search-input.sync="search"
          :disabled="disabled"
          :error-messages="errors"
          :value="value"
          :filter="filterObject"
          v-bind="binds"
          v-on="events"
          autocomplete="off"
          @input="onInput"
          @focus="created"
        >
          <template v-slot:label>
            <div>
              {{ label }}
              <small class="error--text" v-if="isRequired">*</small>
            </div>
          </template>

          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-content>
                <template v-if="!search">{{ $t('label.typeSearch') }}</template>
                <template v-else>{{ $t('label.noData') }}</template>
              </v-list-item-content>
            </v-list-item>
          </template>
          <template v-slot:append-item>
            <div v-intersect="onIntersect" class="pa-4 teal--text"></div>
          </template>
        </v-autocomplete>
      </template>

      <template v-else-if="type === 'dynamic-chips'">
        <v-combobox
          multiple
          small-chips
          solo
          clearable
          hide-selected
          :label="label"
          :items="items"
          :filter="filter"
          :hide-no-data="!search"
          :search-input.sync="search"
          :disabled="disabled"
          :value="value"
          @input="onInput"
        >
          <template v-slot:no-data>
            <v-list-item>
              <span class="subheading">Create</span>
              <v-chip color="secondary">
                {{ search }}
              </v-chip>
            </v-list-item>
          </template>
          <template v-slot:selection="{ attrs, item, parent, selected }">
            <v-chip
              v-if="item === Object(item)"
              color="secondary"
              :input-value="selected"
              v-bind="attrs"
            >
              <span class="pr-2">
                {{ item.label }}
              </span>
              <v-icon small @click="parent.selectItem(item)"> $delete </v-icon>
            </v-chip>
          </template>
          <template v-slot:item="{ index, item }">
            <v-text-field
              v-if="editing === item"
              v-model="editing.label"
              autofocus
              flat
              background-color="transparent"
              hide-details
              solo
              @keyup.enter="edit(index, item)"
            ></v-text-field>
            <v-chip v-else :color="`${item.color} lighten-3`" dark label small>
              {{ item.label }}
            </v-chip>
            <v-spacer></v-spacer>
            <v-list-item-action @click.stop>
              <v-btn icon @click.stop.prevent="edit(index, item)">
                <v-icon>{{
                  editing !== item ? 'mdi-pencil' : 'mdi-check'
                }}</v-icon>
              </v-btn>
            </v-list-item-action>
          </template>
        </v-combobox>
      </template>

      <template v-else-if="type === 'chips'">
        <v-autocomplete
          chips
          multiple
          deletable-chips
          clearable
          :label="label"
          :items="items"
          :error-messages="errors"
          :value="value"
          v-bind="binds"
          v-on="events"
          @input="onInput"
        >
          <!-- @slot default -->
          <slot />

          <template v-slot:selection="data">
            <slot name="selection" :data="data">
              <v-chip
                close
                :input-value="data.selected"
                v-bind="data.attrs"
                @click="data.select"
                @click:close="onRemoveChips(data.item)"
              >
                {{ data.item.label }}
              </v-chip>
            </slot>
          </template>

          <template v-slot:item="data">
            <slot name="item" :data="data">
              {{ data.item.label }}
            </slot>
          </template>
        </v-autocomplete>
      </template>

      <template v-else-if="type === 'chips-server'">
        <v-autocomplete
          chips
          multiple
          deletable-chips
          :cache-items="binds.cacheItems === true"
          return-object
          clearable
          :filter="filterObject"
          :item-text="binds.itemText || 'name'"
          item-value="id"
          :label="label"
          :items="serverItems"
          :loading="loading"
          :search-input.sync="search"
          :error-messages="errors"
          :value="value"
          :disabled="disabled"
          v-bind="binds"
          v-on="events"
          @input="onInput"
        >
          <template v-slot:label>
            <div>
              {{ label }}
              <small class="error--text" v-if="isRequired">*</small>
            </div>
          </template>

          <template v-slot:selection="data">
            <slot name="selection" :data="data">
              <v-chip
                close
                :input-value="data.selected"
                v-bind="{ ...data.attrs, ...(binds.chip || {}) }"
                @click="data.select"
                @click:close="onRemoveServerChips(data.item)"
              >
                <v-icon v-if="binds.chip && binds.chip.icon" left>
                  {{ binds.chip.icon }}
                </v-icon>
                {{ data.item.name }}
              </v-chip>
            </slot>
          </template>

          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-content>
                <template v-if="!search">{{ $t('label.typeSearch') }}</template>
                <template v-else>{{ $t('label.noData') }}</template>
              </v-list-item-content>
            </v-list-item>
          </template>
          <template v-slot:append-item>
            <div v-intersect="onIntersect" class="pa-4 teal--text"></div>
          </template>
        </v-autocomplete>
      </template>

      <template v-else-if="type === 'picture'">
        <div>
          {{ label }} <small class="error--text" v-if="isRequired">*</small>
        </div>
        <picture-input
          hide-change-button
          ref="picture"
          accept="image/jpeg,image/png"
          :class="`mt-1 app-input--picture ${errors.length ? 'error' : ''}`"
          :crop="false"
          :customStrings="{
            drag: $t('label.dragPicture')
          }"
          :z-index="1"
          v-bind="binds"
          @change="onInput"
        />
        <div class="v-text-field__details mt-2" v-if="errors.length">
          <div class="v-messages theme--light error--text" role="alert">
            <div class="v-messages__wrapper">
              <div class="v-messages__message">{{ errors[0] }}</div>
            </div>
          </div>
        </div>
      </template>
    </validation-provider>
  </div>
</template>

<script>
import { getFile } from '@utils/function'
import debounce from 'debounce'
import _ from 'lodash'
import { ValidationProvider } from 'vee-validate'
import PictureInput from 'vue-picture-input'
import { VueEditor } from 'vue2-editor'

export default {
  name: 'app-input',
  components: {
    ValidationProvider,
    PictureInput,
    VueEditor
  },
  props: {
    /**
     * validation name
     */
    name: {
      type: String,
      required: true
    },
    /**
     * validation rules
     */
    rules: {
      type: [String, Object],
      default: ''
    },
    /**
     * label of input
     */
    label: {
      type: String,
      required: true
    },
    /**
     * input type
     */
    type: {
      type: String,
      default: 'text',
      validator: (val) => {
        return [
          'text',
          'number',
          'password',
          'tel',
          'time',
          'range',
          'color',
          'textarea',
          'checkbox',
          'select',
          'select-server',
          'date',
          'radio',
          'switch',
          'slider',
          'file',
          'chips',
          'chips-server',
          'datepicker',
          'picture',
          'dynamic-chips',
          'html-editor',
          'year-selection'
        ].includes(val)
      }
    },
    /**
     * value of input
     */
    value: {
      required: true
    },
    /**
     * view mode
     */
    view: {
      type: Boolean,
      default: false
    },
    /**
     * disabled input
     */
    disabled: {
      type: Boolean,
      default: false
    },
    /**
     * Used for some input type
     */
    items: {
      type: Array,
      default: () => []
    },
    /**
     * bind options for input
     */
    binds: {
      type: Object,
      default: () => ({})
    },
    /**
     * bind options for radio group
     */
    groupBinds: {
      type: Object,
      default: () => ({})
    },
    /**
     * bind options for input
     */
    viewBinds: {
      type: Object,
      default: () => ({})
    },
    /**
     * events options for input
     */
    events: {
      type: Object,
      default: () => ({})
    },
    filterObject: {
      type: Function,
      default() {
        return 'name'
      }
    },
    multiple: {
      type: Boolean,
      default: false
    },
    content: {
      type: String,
      default: ''
    },
    chips: {
      type: Boolean,
      default: false
    },
    isUpperCase: {
      type: Boolean,
      default: false
    },
    // used to display the selected value of select-server
    nameAccessor: {
      type: String,
      default: null
    },

    /**
     * bind event onBlur
     */
    handleBlur: {
      type: Function,
      required: false,
      default: () => {}
    },
    /**
     * bind event KeyDown
     */
    handleKeyDown: {
      type: Function,
      required: false,
      default: () => {}
    }
  },
  computed: {
    isRequired() {
      return this.rules instanceof Object
        ? this.rules.required
        : this.rules.split('|').includes('required')
    },
    isNormalType() {
      return ['text', 'number', 'password', 'tel'].includes(this.type)
    },
    selectServerDisplay() {
      return this.nameAccessor
        ? _.get(this.value, this.nameAccessor)
        : this.value.name
    }
  },
  data() {
    return {
      file: null,
      src: null,
      serverItems: [],
      yearItems: [],
      search: null,
      loading: false,
      editing: null,
      page: 0,
      itemsPerPage: 30,
      selectedYear: null
    }
  },
  watch: {
    '$i18n.locale'(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$refs.validation.errors.length && this.$refs.validation.validate()
      }
    },
    view(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.onChangeMode()
      }
    },
    search(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.onSearch()
      }
    }
  },
  mounted() {
    this.loadMedia()
    this.setDefaultServerItems(this.value)
    if (this.type === 'year-selection') {
      this.generateYearItems()
    }
  },
  methods: {
    SetSelectedServerDropdown() {
      {
        const offset = (this.page - 1) * this.itemsPerPage
        if (this.binds.apiUrl) {
          var url = `${this.binds.apiUrl}`
          this.loading = true
          this.$api({
            method: 'get',
            url: url,
            hideSuccessAlert: true,
            params: {
              limit: this.itemsPerPage,
              offset: offset
            }
          })
            .then(({ data }) => {
              this.serverItems = [...this.serverItems, ...data.results]
            })
            .catch((err) => {
              console.error(err)
            })
            .finally(() => {
              this.loading = false
            })
        }
      }
    },
    created() {
      switch (this.type) {
        case 'select-server':
          this.page = 0
          break
        case 'chips-server':
          this.page = 0
          this.SetSelectedServerDropdown()
          break
      }
    },
    async loadMedia() {
      if (!this.value && !this.view) return
      if (this.type === 'picture') {
        this.file = await getFile(this.value)
        this.src = URL.createObjectURL(this.file)
      }
    },
    generateYearItems() {
      const currentYear = new Date().getFullYear()
      const startYear = 2022 // Adjust according to your needs
      const endYear = currentYear + 10 // Adjust according to your needs
      for (let year = startYear; year <= endYear; year++) {
        this.yearItems.push({
          label: `${year}`,
          value: year
        })
      }
    },
    setDefaultServerItems(value = null) {
      if (!value) return
      switch (this.type) {
        case 'select-server':
          this.serverItems = [value]
          break
        case 'chips-server':
          this.serverItems = value
          break
      }
    },
    onIntersect() {
      this.page += 1
      this.SetSelectedServerDropdown()
    },
    onInput(value) {
      /**
       * Set input for v-model
       * @type {Event}
       */
      if (this.type === 'number') {
        value = Number(value)
      } else if (this.type === 'picture') {
        value = this.$refs.picture.file
        this.file = value
        this.src = URL.createObjectURL(value)
      } else if (this.type === 'dynamic-chips') {
        value = value.map((v) => {
          if (typeof v === 'string') {
            return {
              label: v
            }
          }
          return v
        })
      }

      if (this.isUpperCase) {
        value = value.toUpperCase()
      }

      this.$refs.validation.syncValue(value)
      this.$refs.validation.validate()
      this.$emit('input', value)
    },
    onChangeMode() {
      if (this.type === 'picture' && !this.view && this.file) {
        this.$nextTick(() => {
          const dataTransfer = new DataTransfer()
          dataTransfer.items.add(this.file)
          this.$refs.picture.onFileChange({ target: {}, dataTransfer })
        })
      }
    },
    onSearch: debounce(function () {
      if (!this.search) {
        // this.serverItems = []
      } else if (this.binds.apiUrl) {
        var url = `${this.binds.apiUrl}?search=${this.search}`
        if (this.binds.apiUrl.includes('?')) {
          url = `${this.binds.apiUrl}&search=${this.search}`
        }
        this.loading = true
        this.$api({
          method: 'get',
          url: url,
          hideSuccessAlert: true
        })
          .then(({ data }) => {
            this.page = 0
            this.serverItems = data.results
          })
          .catch((err) => {
            console.error(err)
          })
          .finally(() => {
            this.loading = false
          })
      }
    }, process.env.VUE_APP_DEBOUNCE),
    onRemoveChips(selected) {
      const index = this.value.findIndex((value) => value === selected.value)
      index >= 0 && this.value.splice(index, 1)
    },
    onRemoveServerChips(selected) {
      const index = this.value.findIndex((value) => value.id === selected.id)
      index >= 0 && this.value.splice(index, 1)
    },
    edit(index, item) {
      if (!this.editing) {
        this.editing = item
      } else {
        this.editing = null
      }
    },
    filter(item, queryText, itemText) {
      if (item.header) return false

      const hasValue = (val) => (val != null ? val : '')

      const text = hasValue(itemText)
      const query = hasValue(queryText)

      return (
        text.toString().toLowerCase().indexOf(query.toString().toLowerCase()) >
        -1
      )
    }
  }
}
</script>

<style lang="scss">
.app-input {
  .v {
    &-input {
      padding-top: 12px;
      margin-top: 4px;

      &__slider {
        margin-top: 20px;
      }
    }
  }

  &--approve-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    .app-input-wrapper {
      &:not(.approve) {
        width: 100%;
      }

      &.approve {
        margin-left: 10px;
      }
    }
  }

  &--picture {
    &.error {
      background-color: unset !important;

      .picture-inner {
        border-color: $error !important;
      }
    }

    .preview-container {
      position: relative;
      background-color: #c8c8c840;
    }

    .picture-preview {
      height: 100% !important;
      border: 0.2em dashed #42424226;
      box-sizing: border-box;
      background-color: none !important;
    }

    .picture-inner {
      position: absolute;
      border: none !important;
      top: 0 !important;
      width: 100% !important;
      height: 100% !important;
      margin: 0 !important;
    }
  }

  &--view {
    &-picture {
      object-fit: contain;
    }
  }
}
</style>
